<template>
  <div
    :class="[
      'vms-media-manager__filters',
      isMobileScreen ? 'vms-media-manager__filters--mobile' : '',
    ]"
  >
    <search-component
      class="vd-full-width vd-padding-block-extra-small vd-padding-inline-small"
      :search-text="searchKey"
      @input:search="searchKey = $event"
      @enter:search="handleSearchEnter"
    ></search-component>

    <filters-dropdown
      class="vd-full-width vd-padding-block-extra-small vd-padding-inline-small"
      :dropdown-options="filters"
      :selected-text="selectedFilterText"
      :has-action-buttons="true"
      @handle:apply-click="handleFilterChange"
      @handle:cancel-click="setInitialFilterValues"
    >
      <div slot="filter-dropdown-list">
        <label
          v-for="(filter, index) in filters"
          :key="index"
          :for="`mediaManagerFilterCheckbox-${index}`"
          class="vd-padding-small vd-full-width vd-display-block vms-filters__dropdown--list-options"
        >
          <input
            :id="`mediaManagerFilterCheckbox-${index}`"
            v-model="selectedFilter"
            type="checkbox"
            class="vd-margin-right-small"
            :value="filter.value"
          />

          <span class="vd-align-middle">{{ filter.text }}</span>
        </label>
      </div>
    </filters-dropdown>
    <div
      v-if="showClear"
      :class="[
        'vd-margin-left-small vd-margin-right-medium',
        isMobileScreen ? 'vd-text-align-right' : 'vd-display-inline-block',
      ]"
    >
      <button class="vd-a" @click="clearFilters">Clear</button>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import FiltersDropdown from '@components/filters/filter-dropdown'
import SearchComponent from '@components/search/search'
import MediaManagerFilters from '@configs/media-manager-filters'
import { find, size, isEmpty, join, isEqual } from 'lodash'

export default {
  components: {
    FiltersDropdown,
    SearchComponent,
  },

  data() {
    return {
      filters: MediaManagerFilters.filters,
      currentFilter: [],
      selectedFilter: [],
      selectedFilterText: 'Select filter',
      searchKey: '',
    }
  },

  computed: {
    ...mapGetters({
      isMobileScreen: 'common/isMobileScreen',
    }),

    showClear() {
      return (
        !isEmpty(this.searchKey) ||
        !isEqual(this.selectedFilter, [this.filters[0].value])
      )
    },
  },

  beforeMount() {
    this.setInitialFilterValues()
  },

  methods: {
    handleFilterChange() {
      if (isEmpty(this.selectedFilter)) {
        this.selectedFilter = [this.filters[0].value]
      }

      this.currentFilter = this.selectedFilter

      this.selectedFilterText = this.getSelectedFilterText()

      this.$emit('update:filters', {
        search: this.searchKey,
        filter: join(this.selectedFilter, ','),
      })
    },

    handleSearchEnter(searchKey) {
      this.searchKey = searchKey
      this.handleFilterChange()
    },

    setInitialFilterValues() {
      if (isEmpty(this.currentFilter)) {
        this.currentFilter = [this.filters[0].value]
      }

      this.selectedFilter = this.currentFilter

      this.selectedFilterText = this.getSelectedFilterText()
      this.searchKey = ''
    },

    getSelectedFilterText() {
      if (size(this.selectedFilter) === size(this.filters)) {
        return 'All'
      }
      if (size(this.selectedFilter) > 1) {
        return 'Multiple is selected'
      }
      return find(
        this.filters,
        (filter) => filter.value === this.selectedFilter[0]
      ).text
    },

    clearFilters() {
      this.selectedFilter = [this.filters[0].value]
      this.currentFilter = this.selectedFilter
      this.selectedFilterText = this.getSelectedFilterText()
      this.searchKey = ''
      this.handleFilterChange()
    },
  },
}
</script>
