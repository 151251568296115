<template>
  <v-app>
    <div
      :class="[
        'vms-media-manager__header vd-background-aqua vd-padding-block-extra-small vd-position-relative vd-margin-bottom-medium',
        isArchivedProjectsLoading ? 'vms-media-manager__header--loading' : '',
        isMediumScreen
          ? 'vms-media-manager__header--mobile'
          : 'container-fluid',
      ]"
    >
      <!-- search & filters -->
      <project-list-search-filters
        @update:filters="updateFilters"
      ></project-list-search-filters>

      <hr
        v-show="isMediumScreen"
        class="vd-border-grey vd-border-width-0 vd-border-top-width-1 vd-margin-block-small"
      />

      <pagination-component
        :class="[
          'vms-media-manager__pagination vd-padding-block-small',
          isMobileScreen ? 'vms-media-manager__pagination--mobile' : '',
        ]"
        :page="tableOptions.page"
        :per-page="archivedProjectsMeta.per_page"
        :last-page="archivedProjectsMeta.last_page"
        :total-rows="archivedProjectsMeta.total"
        :limit-options="itemsPerPageOptions"
        :show-page-in-route="false"
        @update:page="currentPageUpdate"
        @update:per_page="archivedProjectsPerPageUpdate"
      ></pagination-component>
    </div>

    <div
      :class="[
        'vms-media-manager__table-wrapper container-fluid',
        isMobileScreen ? 'vms-media-manager__table-wrapper--mobile' : '',
      ]"
    >
      <!-- using meta current_page and per_page for initial data-table pagination value -->
      <v-data-table
        :class="[
          'vms-media-manager__table vd-margin-bottom-medium',
          isMobileScreen ? '' : 'vd-border-light-grey',
        ]"
        loading-text="Loading Projects..."
        :headers="projectHeaders"
        :items="archivedProjectsList"
        :mobile-breakpoint="tableBreakpoint"
        :page="archivedProjectsMeta.current_page"
        :items-per-page="archivedProjectsMeta.per_page"
        :loading="isTableLoading"
        :fixed-header="!isMobileScreen"
        :options.sync="tableOptions"
        :server-items-length="archivedProjectsMeta.total"
        hide-default-footer
        @update:options="updateProjectList"
      >
        <projects-list-no-results slot="no-data"></projects-list-no-results>

        <template v-slot:[`item.id`]="{ item }">
          <router-link
            slot="popover-label"
            class="vd-a"
            :to="{
              name: 'project.details.summary',
              params: { projectId: item.id },
            }"
            >#{{ item.id }}</router-link
          >
        </template>

        <template v-slot:[`item.archived_at`]="{ item }">
          {{ item.archived_at | unixToFormat('DD/MM/YYYY') }}
        </template>

        <template v-slot:[`item.producer`]="{ item }">
          <staff-popover
            v-if="item.producer"
            slot="input-prepend"
            class="vd-cursor-pointer vd-padding-block-small"
            :selected-user="item.producer"
            :tooltip-id="`project-manager-${item.id}`"
            :label-avatar-font-size="45"
          ></staff-popover>
        </template>

        <template v-slot:[`item.office`]="{ item }">
          {{ item.office.name }}
        </template>

        <template v-slot:[`item.footage_status_type_id`]="{ item }">
          {{ getFootageStatusType(item.footage_status_type_id).name }}
        </template>

        <template v-slot:[`item.footage_policy_type_id`]="{ item }">
          {{ getFootagePolicyType(item.footage_policy_type_id).name }}
        </template>

        <template v-slot:[`item.actions`]="{ item }">
          <router-link
            slot="popover-label"
            class="vd-a"
            :to="{
              name: 'project.details.summary',
              params: { projectId: item.id },
            }"
          >
            <text-popover
              use-label-slot
              class="vd-display-inline-block"
              tooltip-text="View Project"
              tooltip-placement="topleft"
              :tooltip-id="`viewProject${item.id}`"
            >
              <i
                :id="`viewProject${item.id}`"
                slot="popover-label"
                class="vd-icon icon-eye vd-h3 vd-darker-grey vd-margin-right-small vd-cursor-pointer"
              ></i>
            </text-popover>
          </router-link>

          <text-popover
            use-label-slot
            class="vd-display-inline-block"
            tooltip-text="Project notes"
            tooltip-placement="topleft"
            :tooltip-id="`showProjectNotes${item.id}`"
          >
            <i
              :id="`showProjectNotes${item.id}`"
              slot="popover-label"
              class="vd-icon icon-chat vd-h3 vd-darker-grey vd-margin-right-small vd-cursor-pointer"
              @click="openProjectNotesModal(item)"
            ></i>
          </text-popover>

          <text-popover
            use-label-slot
            class="vd-display-inline-block"
            tooltip-text="Action archived project"
            tooltip-placement="topleft"
            :tooltip-id="`archiveProjectFiles${item.id}`"
          >
            <i
              :id="`archiveProjectFiles${item.id}`"
              slot="popover-label"
              class="vd-icon icon-check vd-h3 vd-darker-grey vd-cursor-pointer"
              @click="openArchiveProjectFootageModal(item)"
            ></i>
          </text-popover>
        </template>
      </v-data-table>

      <!-- footer pagination -->
      <pagination-component
        v-if="!isArchivedProjectsLoading && hasProjects"
        :class="[
          'vms-media-manager__pagination vms-pagination--bottom',
          isMobileScreen
            ? 'vms-media-manager__pagination--mobile vd-margin-bottom-medium'
            : '',
        ]"
        :page="tableOptions.page"
        :per-page="archivedProjectsMeta.per_page"
        :last-page="archivedProjectsMeta.last_page"
        :total-rows="archivedProjectsMeta.total"
        :limit-options="itemsPerPageOptions"
        :show-page-in-route="false"
        @update:page="currentPageUpdate"
        @update:per_page="archivedProjectsPerPageUpdate"
      ></pagination-component>
    </div>

    <archive-project-footage-modal
      v-if="showArchiveProjectFootageModal"
    ></archive-project-footage-modal>

    <eod-notes-modal
      v-if="showEodModal"
      :project-id="eodNotesSelectedProject.id"
      :project-name="eodNotesSelectedProject.name"
    >
    </eod-notes-modal>
  </v-app>
</template>

<script>
import Vuetify from 'vuetify'
import PaginationComponent from '@components/pagination/pagination'
import StaffPopover from '@components/staff-popover/staff-popover'
import ProjectListSearchFilters from '@views/media-manager/components/project-list-search-filters'
import ProjectsListNoResults from '@components/empty-states/projects/projects-list-no-results'
import TextPopover from '@components/tooltips/text-popover'
import ArchiveProjectFootageModal from '@views/media-manager/components/archive-project-footage-modal'
import ApiCustomIncludes from '@configs/api-custom-includes'
import FootagePolicyTypes from '@configs/footage-policy-types'
import MediaManagerFilters from '@configs/media-manager-filters'
import FiltersMixin from '@mixins/filters-mixin'
import EodNotesModal from '@components/eod-notes/eod-notes-modal'
import { mapGetters, mapActions } from 'vuex'
import { find, assign, isEmpty, size, isEqual } from 'lodash'

export default {
  components: {
    PaginationComponent,
    StaffPopover,
    ProjectListSearchFilters,
    ProjectsListNoResults,
    TextPopover,
    ArchiveProjectFootageModal,
    EodNotesModal,
  },

  vuetify: new Vuetify(),

  mixins: [FiltersMixin],

  data() {
    return {
      projectHeaders: [
        {
          text: 'Date Archived',
          sortable: true,
          value: 'archived_at',
          width: '150px',
          align: 'center',
        },
        {
          text: 'Project ID',
          sortable: true,
          value: 'id',
          width: '100px',
          align: 'center',
        },
        {
          text: 'Project Manager',
          sortable: true,
          value: 'producer',
          align: 'center',
          width: '100px',
        },
        {
          text: 'Project Name',
          sortable: true,
          value: 'name',
          width: '250px',
        },
        {
          text: 'Office name',
          sortable: true,
          value: 'office',
          width: '250px',
        },
        {
          text: 'Footage Status',
          sortable: true,
          value: 'footage_status_type_id',
          width: '250px',
        },
        {
          text: 'Raw Footage Policy',
          sortable: true,
          value: 'footage_policy_type_id',
          width: '250px',
        },
        {
          text: 'Actions',
          sortable: false,
          filterable: false,
          value: 'actions',
          width: '120px',
          align: 'center',
        },
      ],
      tableOptions: {},
      itemsPerPageOptions: [10, 25, 50, 75, 100],
      // based on <= 768 breakpoint
      tableBreakpoint: 769,

      currentFilters: {
        filter: MediaManagerFilters.filters[0].value,
        search: '',
      },
    }
  },

  computed: {
    ...mapGetters({
      isMediumScreen: 'common/isMediumScreen',
      isMobileScreen: 'common/isMobileScreen',
      archivedProjectsList: 'projects/archivedProjectsList',
      archivedProjectsMeta: 'projects/archivedProjectsMeta',
      isArchivedProjectsLoading: 'media-manager/isArchivedProjectsLoading',
      showArchiveProjectFootageModal:
        'media-manager/showArchiveProjectFootageModal',
      isArchiveProjectFootageLoading:
        'media-manager/isArchiveProjectFootageLoading',
      footageStatusTypesList: 'footage-status-type/footageStatusTypesList',
      isFootageStatusTypesLoading:
        'footage-status-type/isFootageStatusTypesLoading',
      showEodModal: 'project/showEodModal',
      eodNotesSelectedProject: 'project/eodNotesSelectedProject',
    }),

    hasProjects() {
      return size(this.archivedProjectsList) > 0
    },

    isTableLoading() {
      return this.isArchivedProjectsLoading || this.isFootageStatusTypesLoading
    },
  },

  watch: {
    // refetch project list after successful action
    isArchiveProjectFootageLoading(isLoading) {
      if (!isLoading && !this.showArchiveProjectFootageModal) {
        this.updateProjectList()
      }
    },
  },

  mounted() {
    if (isEmpty(this.footageStatusTypesList)) {
      this.getFootageStatusTypes()
    }
  },

  methods: {
    ...mapActions({
      getArchivedProjects: 'media-manager/getArchivedProjects',
      setSelectedArchivedProject: 'media-manager/setSelectedArchivedProject',
      toggleArchiveProjectFootageModal:
        'media-manager/toggleArchiveProjectFootageModal',
      getFootageStatusTypes: 'footage-status-type/getFootageStatusTypes',
      toggleEodNotesModal: 'project/toggleEodNotesModal',
      setEodNotesSelectedProject: 'project/setEodNotesSelectedProject',
    }),

    updateProjectList() {
      const { page, itemsPerPage, sortBy, sortDesc } = this.tableOptions
      const { filter, search } = this.currentFilters

      // set required payload
      const payload = {
        page,
        itemsPerPage,
      }

      // set optional payload
      if (!isEmpty(filter)) {
        assign(payload, { filter })
      }

      if (!isEmpty(search)) {
        assign(payload, { search })
      }

      if (!isEmpty(sortBy)) {
        assign(payload, { sortBy: sortBy[0] })
        if (!isEmpty(sortDesc)) {
          assign(payload, { sort: sortDesc[0] ? 'desc' : 'asc' })
        }
      }

      // get archived projects list
      this.getArchivedProjects({
        payload,
        customIncludes: ApiCustomIncludes.mediaManagerArchivedProjectList,
      })
    },

    currentPageUpdate(page) {
      this.tableOptions.page = page
    },

    archivedProjectsPerPageUpdate(perPage) {
      assign(this.tableOptions, {
        itemsPerPage: perPage,
        page: 1,
      })
    },

    getFootageStatusType(statusId) {
      return (
        find(this.footageStatusTypesList, {
          id: statusId,
        }) || { id: statusId, name: '' }
      )
    },

    getFootagePolicyType(policyId) {
      return (
        find(FootagePolicyTypes.policies, { id: policyId }) || {
          id: policyId,
          name: '',
          key: '',
        }
      )
    },

    updateFilters(newFilters) {
      if (!isEqual(newFilters, this.currentFilters)) {
        this.currentFilters = newFilters
        // if current page is 1: call updateProjectList
        // else reset page to 1: v-data-table update:options will trigger updateProjectList
        // We do this to avoid calling updateProjectList twice
        if (this.tableOptions.page === 1) {
          this.updateProjectList()
        } else {
          this.tableOptions.page = 1
        }
      }
    },

    openArchiveProjectFootageModal(project) {
      const selectedArchivedProject = assign(project, {
        footageStatus: this.getFootageStatusType(
          project.footage_status_type_id
        ),
        footagePolicy: this.getFootagePolicyType(
          project.footage_policy_type_id
        ),
      })
      this.setSelectedArchivedProject(selectedArchivedProject)
      this.toggleArchiveProjectFootageModal()
    },

    openProjectNotesModal(project) {
      const projectObj = {
        id: project.id,
        name: project.name,
      }

      this.setEodNotesSelectedProject(projectObj)
      this.toggleEodNotesModal(true)
    },
  },
}
</script>

<style lang="scss">
@import '@styles/plugins/vuetify';
@import '@styles/plugins/vue-search-select';
</style>
