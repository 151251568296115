<template>
  <default-modal
    :class="[
      'show vd-text-align-left',
      isMobileScreen ? 'vms-modal--fullscreen' : '',
    ]"
    modal-dialog-class="modal-lg"
    content-class="vd-background-white"
  >
    <div slot="modal-header" class="modal-header vms-modal__header">
      <div>
        <div class="vd-h4 vd-black">
          <i
            class="vd-icon icon-box-archive vd-margin-right-small vd-darker-grey"
          ></i>
          Update footage policy and status
        </div>
      </div>
      <div class="vms-modal__header--close close vd-black">
        <span @click.prevent="handleCancel">x</span>
      </div>
    </div>

    <div slot="modal-body" class="modal-body">
      <div class="vd-margin-bottom-medium">
        <div
          :class="[
            'vd-field vd-required',
            form.footagePolicy.id ? 'vd-input-valid' : '',
          ]"
        >
          <label>Raw footage policy:</label>
          <div v-if="isArchiveProjectFootageLoading">
            {{ form.footagePolicy.name }}
          </div>
          <model-list-select
            v-else
            v-model="form.footagePolicy"
            name="footagePolicy"
            :list="footagePolicyTypes"
            option-value="key"
            option-text="name"
            :option-disabled="false"
            placeholder="Select footage policy"
            :disabled="true"
          ></model-list-select>
        </div>
      </div>

      <div v-if="hasFootagePolicyDocumentLinks" class="vd-margin-bottom-medium">
        <div class="vd-field">
          <label>Link to Policy:</label>
          <ul>
            <li
              v-for="(document, index) in footagePolicyDocumentLinks"
              :key="index"
            >
              <a :href="document.file_link" class="text-break" target="_blank">
                {{ document.file_link | getLastUrlSegment }}
              </a>
            </li>
          </ul>
        </div>
      </div>

      <div class="vd-margin-bottom-medium">
        <div class="vd-field">
          <label for="">Notes:</label>
          <textarea
            v-model="form.footagePolicyNotes"
            placeholder="Enter notes here"
            :disabled="isArchiveProjectFootageLoading"
          ></textarea>
        </div>
      </div>

      <div class="vd-margin-bottom-medium">
        <div
          :class="[
            'vd-field vd-required',
            form.footageStatus.id ? 'vd-input-valid' : '',
          ]"
        >
          <label>Media manager action taken (footage status):</label>
          <div v-if="isArchiveProjectFootageLoading">
            {{ form.footageStatus.name }}
          </div>
          <model-list-select
            v-else
            v-model="form.footageStatus"
            name="footageStatus"
            :list="footageStatusTypesList"
            option-value="id"
            option-text="name"
            :option-disabled="false"
            placeholder="Select footage status"
          ></model-list-select>
        </div>
      </div>
    </div>

    <div slot="modal-footer" class="modal-footer">
      <div class="vd-text-align-right">
        <button
          id="cancelArchiveProjectFootageModalButton"
          class="vd-btn-small vd-btn-grey vd-margin-right-small"
          :disabled="isArchiveProjectFootageLoading"
          @click.prevent="handleCancel"
        >
          Cancel
        </button>
        <button
          id="saveArchiveProjectFootageModalButton"
          class="vd-btn-small vd-btn-blue"
          :disabled="disableSaveButton"
          @click="handleSave"
        >
          {{ isArchiveProjectFootageLoading ? 'Saving...' : 'Save' }}
        </button>
      </div>
    </div>
  </default-modal>
</template>

<script>
import DefaultModal from '@components/modal/default-modal'
import FootagePolicyTypes from '@configs/footage-policy-types'
import FiltersMixin from '@mixins/filters-mixin'
import FormMixin from '@mixins/forms-mixin'
import { required } from 'vuelidate/lib/validators'
import { ModelListSelect } from 'vue-search-select'
import { mapActions, mapGetters } from 'vuex'
import { filter, size, isEmpty, find } from 'lodash'

export default {
  components: {
    DefaultModal,
    ModelListSelect,
  },

  mixins: [FiltersMixin, FormMixin],

  validations: {
    form: {
      footagePolicy: {
        id: { required },
      },

      footageStatus: {
        id: { required },
      },
    },
  },

  data() {
    return {
      form: {
        footagePolicy: {},
        footageStatus: {},
        footagePolicyNotes: '',
      },
    }
  },

  computed: {
    ...mapGetters({
      isMobileScreen: 'common/isMobileScreen',
      selectedArchivedProject: 'media-manager/selectedArchivedProject',
      isArchiveProjectFootageLoading:
        'media-manager/isArchiveProjectFootageLoading',
      footageStatusTypesList: 'footage-status-type/footageStatusTypesList',
    }),

    footagePolicyTypes() {
      return FootagePolicyTypes.policies
    },

    hasFootagePolicyDocumentLinks() {
      return size(this.footagePolicyDocumentLinks) > 0
    },

    footagePolicyDocumentLinks() {
      const documents = this.selectedArchivedProject?.office?.company
        ?.footage_policy_documents

      if (documents) {
        return filter(documents, (document) => document.file_link)
      }

      return []
    },

    disableSaveButton() {
      return this.isArchiveProjectFootageLoading || this.$v.$invalid
    },
  },

  mounted() {
    this.setInitialFormData()
  },

  methods: {
    ...mapActions({
      toggleArchiveProjectFootageModal:
        'media-manager/toggleArchiveProjectFootageModal',
      setSelectedArchivedProject: 'media-manager/setSelectedArchivedProject',
      archiveProjectFootage: 'media-manager/archiveProjectFootage',
    }),

    setInitialFormData() {
      if (!isEmpty(this.selectedArchivedProject)) {
        this.form.footagePolicy = this.selectedArchivedProject.footagePolicy
        this.form.footageStatus =
          find(this.footageStatusTypesList, {
            id: this.selectedArchivedProject.footageStatus?.id,
          }) || {}
        this.form.footagePolicyNotes =
          this.selectedArchivedProject.footage_policy_notes || ''
      }

      // initialize validation
      this.$v.$touch()
    },

    handleCancel() {
      if (!this.isArchiveProjectFootageLoading) {
        this.toggleArchiveProjectFootageModal()
        this.setSelectedArchivedProject(null)
      }
    },

    handleSave() {
      this.archiveProjectFootage({
        projectId: this.selectedArchivedProject.id,
        payload: {
          footage_policy_type_id: this.form.footagePolicy.id,
          footage_status_type_id: this.form.footageStatus.id,
          footage_policy_notes: this.form.footagePolicyNotes,
        },
      })
    },
  },
}
</script>
