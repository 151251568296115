export default Object.freeze({
  filters: [
    {
      value: 'actionable',
      text: 'Actionable projects (older than 3 months)',
    },
    {
      value: 'pending',
      text: 'Pending projects (less than 3 months)',
    },
    {
      value: 'future',
      text: 'Future action required',
    },
  ],
})
