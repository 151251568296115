var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-app',[_c('div',{class:[
      'vms-media-manager__header vd-background-aqua vd-padding-block-extra-small vd-position-relative vd-margin-bottom-medium',
      _vm.isArchivedProjectsLoading ? 'vms-media-manager__header--loading' : '',
      _vm.isMediumScreen
        ? 'vms-media-manager__header--mobile'
        : 'container-fluid' ]},[_c('project-list-search-filters',{on:{"update:filters":_vm.updateFilters}}),_c('hr',{directives:[{name:"show",rawName:"v-show",value:(_vm.isMediumScreen),expression:"isMediumScreen"}],staticClass:"vd-border-grey vd-border-width-0 vd-border-top-width-1 vd-margin-block-small"}),_c('pagination-component',{class:[
        'vms-media-manager__pagination vd-padding-block-small',
        _vm.isMobileScreen ? 'vms-media-manager__pagination--mobile' : '' ],attrs:{"page":_vm.tableOptions.page,"per-page":_vm.archivedProjectsMeta.per_page,"last-page":_vm.archivedProjectsMeta.last_page,"total-rows":_vm.archivedProjectsMeta.total,"limit-options":_vm.itemsPerPageOptions,"show-page-in-route":false},on:{"update:page":_vm.currentPageUpdate,"update:per_page":_vm.archivedProjectsPerPageUpdate}})],1),_c('div',{class:[
      'vms-media-manager__table-wrapper container-fluid',
      _vm.isMobileScreen ? 'vms-media-manager__table-wrapper--mobile' : '' ]},[_c('v-data-table',{class:[
        'vms-media-manager__table vd-margin-bottom-medium',
        _vm.isMobileScreen ? '' : 'vd-border-light-grey' ],attrs:{"loading-text":"Loading Projects...","headers":_vm.projectHeaders,"items":_vm.archivedProjectsList,"mobile-breakpoint":_vm.tableBreakpoint,"page":_vm.archivedProjectsMeta.current_page,"items-per-page":_vm.archivedProjectsMeta.per_page,"loading":_vm.isTableLoading,"fixed-header":!_vm.isMobileScreen,"options":_vm.tableOptions,"server-items-length":_vm.archivedProjectsMeta.total,"hide-default-footer":""},on:{"update:options":[function($event){_vm.tableOptions=$event},_vm.updateProjectList]},scopedSlots:_vm._u([{key:"item.id",fn:function(ref){
      var item = ref.item;
return [_c('router-link',{staticClass:"vd-a",attrs:{"slot":"popover-label","to":{
            name: 'project.details.summary',
            params: { projectId: item.id },
          }},slot:"popover-label"},[_vm._v("#"+_vm._s(item.id))])]}},{key:"item.archived_at",fn:function(ref){
          var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("unixToFormat")(item.archived_at,'DD/MM/YYYY'))+" ")]}},{key:"item.producer",fn:function(ref){
          var item = ref.item;
return [(item.producer)?_c('staff-popover',{staticClass:"vd-cursor-pointer vd-padding-block-small",attrs:{"slot":"input-prepend","selected-user":item.producer,"tooltip-id":("project-manager-" + (item.id)),"label-avatar-font-size":45},slot:"input-prepend"}):_vm._e()]}},{key:"item.office",fn:function(ref){
          var item = ref.item;
return [_vm._v(" "+_vm._s(item.office.name)+" ")]}},{key:"item.footage_status_type_id",fn:function(ref){
          var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getFootageStatusType(item.footage_status_type_id).name)+" ")]}},{key:"item.footage_policy_type_id",fn:function(ref){
          var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getFootagePolicyType(item.footage_policy_type_id).name)+" ")]}},{key:"item.actions",fn:function(ref){
          var item = ref.item;
return [_c('router-link',{staticClass:"vd-a",attrs:{"slot":"popover-label","to":{
            name: 'project.details.summary',
            params: { projectId: item.id },
          }},slot:"popover-label"},[_c('text-popover',{staticClass:"vd-display-inline-block",attrs:{"use-label-slot":"","tooltip-text":"View Project","tooltip-placement":"topleft","tooltip-id":("viewProject" + (item.id))}},[_c('i',{staticClass:"vd-icon icon-eye vd-h3 vd-darker-grey vd-margin-right-small vd-cursor-pointer",attrs:{"slot":"popover-label","id":("viewProject" + (item.id))},slot:"popover-label"})])],1),_c('text-popover',{staticClass:"vd-display-inline-block",attrs:{"use-label-slot":"","tooltip-text":"Project notes","tooltip-placement":"topleft","tooltip-id":("showProjectNotes" + (item.id))}},[_c('i',{staticClass:"vd-icon icon-chat vd-h3 vd-darker-grey vd-margin-right-small vd-cursor-pointer",attrs:{"slot":"popover-label","id":("showProjectNotes" + (item.id))},on:{"click":function($event){return _vm.openProjectNotesModal(item)}},slot:"popover-label"})]),_c('text-popover',{staticClass:"vd-display-inline-block",attrs:{"use-label-slot":"","tooltip-text":"Action archived project","tooltip-placement":"topleft","tooltip-id":("archiveProjectFiles" + (item.id))}},[_c('i',{staticClass:"vd-icon icon-check vd-h3 vd-darker-grey vd-cursor-pointer",attrs:{"slot":"popover-label","id":("archiveProjectFiles" + (item.id))},on:{"click":function($event){return _vm.openArchiveProjectFootageModal(item)}},slot:"popover-label"})])]}}],null,true)},[_c('projects-list-no-results',{attrs:{"slot":"no-data"},slot:"no-data"})],1),(!_vm.isArchivedProjectsLoading && _vm.hasProjects)?_c('pagination-component',{class:[
        'vms-media-manager__pagination vms-pagination--bottom',
        _vm.isMobileScreen
          ? 'vms-media-manager__pagination--mobile vd-margin-bottom-medium'
          : '' ],attrs:{"page":_vm.tableOptions.page,"per-page":_vm.archivedProjectsMeta.per_page,"last-page":_vm.archivedProjectsMeta.last_page,"total-rows":_vm.archivedProjectsMeta.total,"limit-options":_vm.itemsPerPageOptions,"show-page-in-route":false},on:{"update:page":_vm.currentPageUpdate,"update:per_page":_vm.archivedProjectsPerPageUpdate}}):_vm._e()],1),(_vm.showArchiveProjectFootageModal)?_c('archive-project-footage-modal'):_vm._e(),(_vm.showEodModal)?_c('eod-notes-modal',{attrs:{"project-id":_vm.eodNotesSelectedProject.id,"project-name":_vm.eodNotesSelectedProject.name}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }