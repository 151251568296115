<template>
  <main-layout class="vms-media-manager">
    <div
      slot="content-body"
      :class="[
        'vms-media-manager__content',
        isMobileScreen
          ? 'vms-media-manager__content--mobile'
          : 'vms-content__container',
      ]"
    >
      <div
        class="vd-margin-left-medium vd-margin-right-medium vd-margin-top-small"
      >
        <div class="row no-gutters">
          <div class="col-12 vms-breadcrumb">
            <a
              id="viewHomePageLink"
              class="vd-a vd-icon-home"
              :href="homePageUrl"
            ></a>
            <span
              class="vd-light-grey vd-margin-right-extra-small vd-margin-left-extra-small"
            >
              |
            </span>
            <span class="vd-black">Media Manager - Archived Projects</span>
          </div>
        </div>

        <div
          class="vd-h2 vd-light-blue vd-margin-top-medium vd-margin-bottom-medium"
        >
          Media Manager - Archived Projects
        </div>
      </div>

      <project-list
        v-if="true"
        class="vms-media-manager__projects-list"
      ></project-list>

      <default-loader
        v-else
        class="vms-loader__visdom-shield--full"
      ></default-loader>
    </div>
  </main-layout>
</template>

<script>
import MainLayout from '@/layouts/main-layout'
import AppConfig from '@configs/app-config'
import DefaultLoader from '@components/loaders/default-loader'
import ProjectList from '@views/media-manager/components/project-list'
import { mapGetters } from 'vuex'

export default {
  components: {
    MainLayout,
    DefaultLoader,
    ProjectList,
  },

  computed: {
    ...mapGetters({
      isMobileScreen: 'common/isMobileScreen',
    }),

    homePageUrl() {
      return `${AppConfig.vmsApiUrl}`
    },
  },
}
</script>

<style lang="scss">
@import '@styles/v2/views/media-manager/media-manager-main';
</style>
